import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyCscV0LAEgVOIut8cJ9mX9Jp-bCGU-Kuug",
    authDomain: "bcloud-9e7e3.firebaseapp.com",
    projectId: "bcloud-9e7e3",
    storageBucket: "bcloud-9e7e3.appspot.com",
    messagingSenderId: "780386030609",
    appId: "1:780386030609:web:7184c3ad9597790ca7cdb0"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore()

export {db, firebase}