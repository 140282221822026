import React from 'react'

const Header = () => {
    return (
        <header className="header">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <img src="../images/logo-gris.svg" alt="BCloud" title="BCloud" className="header__logo float-start"/>
                        <div className="menu float-end">
                            <ul className="menu-opciones">
                                <li className="menu-login menu-opcion gradiente sombra">
                                    <a 
                                        href="https://boleta.cloud/router.php#/main"
                                        rel="noreferrer"
                                        title="Login"
                                        style={{backgroundImage:'url(images/login.svg)'}}
                                    >
                                        <h3>Ingresar</h3>
                                    </a>
                                </li>
                                <li className="menu-llamar menu-opcion gradiente sombra">
                                    <a 
                                        href="tel:+56994791732"
                                        rel="noreferrer" 
                                        title="Llamar"
                                        style={{backgroundImage:'url(images/telefono.svg)'}}
                                    >
                                        <h3>Llámanos</h3>
                                    </a>
                                </li>
                                <li className="menu-whatsapp menu-opcion sombra">
                                    <a 
                                        href="https://api.whatsapp.com/send?phone=+56994791732"
                                        rel="noreferrer"
                                        target="_blank"
                                        title="Whatsapp"
                                        style={{backgroundImage:'url(images/whatsapp.svg)'}}
                                    >
                                        <h3>Escríbenos</h3>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
