import React from 'react'

const Carrusel = () => {
    return (
        <ul className="tips my-5 my-lg-3">
            <li className="tips--tip" style={{backgroundImage: 'url(images/clock.svg)'}}>
                <p>
                    <strong>Certificación más rápida del mercado</strong>
                    En 48 horas ya estas emitiendo tus boletas
                </p>
            </li>
            <li className="tips--tip" style={{backgroundImage: 'url(images/hand-shake.svg)'}}>
                <p>
                    <strong>Capacitación en terreno</strong>
                    Visitamos a todos nuestros clientes <small>(Región Metropolitana)</small>.
                </p>
            </li>
            <li className="tips--tip" style={{backgroundImage: 'url(images/support.svg)'}}>
                <p>
                    <strong>Asistencia Personalizada</strong>
                    Disponible para responder todas tus dudas.
                </p>
            </li>
            <li className="tips--tip" style={{backgroundImage: 'url(images/pos.svg)'}}>
                <p>
                    <strong>Sistema de impresión rápida y directa</strong>
                    Fácil de usar.
                </p>
            </li>
            <li className="tips--tip" style={{backgroundImage: 'url(images/thumbs-up.svg)'}}>
                <p>
                    <strong> Sin contratos ni amarres</strong>
                    Pagas sólo si estás conforme.
                </p>
            </li>
        </ul>
    )
}

export default Carrusel
