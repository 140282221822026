import React, {useState} from 'react'
import Header from './components/Header';
import Carrusel from './components/Carrusel';
import PopUp from './components/PopUp';

function App() {

  const [show, setShow] = useState(false)

  return (
    <>
    <Header/>
    <PopUp show={show} setShow={setShow}/>
    <div className="landing">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 col-lg-6 align-self-center">
            <div className="llamado">
              <h1 className="llamado--titulo text-center"><span>Boleta.</span>Cloud</h1>
              <h2 className="llamado--subtitulo mt-2 mt-md-3 text-center">Con nosotros, cambiarse a boleta electrónica <br className="d-none d-md-block"/>será una muy buena experiencia</h2>
              <h3 className="llamado--bajada mt-3 text-center"><strong>Boleta electrónica SII con reportes!</strong></h3>
            </div>    
            <Carrusel/>
            <img src="images/pos.png" alt="Máquin POS" className="pos"/>
            <button 
              className="btn btn-primary btn-lg mt-4 mx-auto d-block py-3 px-4 fs-2 fw-bold"
              onClick={e=>setShow(true)}
            >
              Inscríbete aquí por $9.990 <small>mensual</small>
            </button>  
            <div className="d-block d-md-none bloque-falso">&nbsp;</div>
          </div>
        </div>
      </div>
      <div className="landing__bg">
        <div className="wave" style={{backgroundImage: 'url(images/wave.svg)'}} />
        <img src="images/bg.jpg?im_q=highq&im_w=1920" alt="Fondo"/>
      </div>
    </div>
    </>
  );
}

export default App;
