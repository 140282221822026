import React from 'react'
import {Modal} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {db} from '../firebase'
import emailjs from 'emailjs-com'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const PopUp = ({show, setShow}) => {

    const handleClose = () => {
        setShow(false)
    }

    const {register, formState, handleSubmit, reset} = useForm({mode: 'onChange', reValidateMode:'onChange'}) 

    const onSubmit = async(data) => {
        try{
            await db.collection('registros').doc(data.email).set({
                nombre: data.nombre,
                telefono: data.telefono,
                rut: data.rut,
                email: data.correo
            })

            const templateParams = {
                nombre: data.nombre,
                telefono: data.telefono,
                rut: data.rut,
                email: data.correo
            }
            emailjs
                .send("service_266zw4u", "template_3iuhw2u", templateParams, 'user_XkEPOEIGitU9BxBvJL6eY')
                .then(
                (result)=>{
                    toast.success("¡Tus datos fueron enviados con éxito!", {
                        position: 'bottom-center', autoClose: 3000
                    });
                },
                (error)=>{
                    toast.error("Ha ocurrido un error, por favor vuelve a intentarlo.", {
                        position: 'bottom-center', autoClose: 3000
                    });
                }
            );
            reset()
            handleClose()
        }
        catch(error){
            console.log(error)
        }
        
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Body>
                <div className="row justify-content-center py-3">
                    <button 
                        type="button"
                        className="btn-close float-end"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={handleClose}
                    ></button>
                    <form className="col-11" onSubmit={handleSubmit(onSubmit)}>
                        <h2 className="mt-4 mb-5">Inscríbete y nos pondremos <span>en contacto contigo</span></h2>
                        <div className="mb-4">
                            <label htmlFor="nombre" className="form-label">Nombre</label>
                            <input 
                                name="nombre"
                                type="text" 
                                className="form-control bg-light border-0" 
                                id="nombre" 
                                placeholder="Nombre y apellido"
                                {...register('nombre', {required:true})}
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="rut" className="form-label">RUT</label>
                            <input 
                                name="rut"
                                type="text"
                                className="form-control bg-light border-0"
                                id="rut"
                                placeholder="Rut"
                                {...register('rut', {required:true})}
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="telefono" className="form-label">Teléfono</label>
                            <input 
                                name='telefono'
                                type="number"
                                className="form-control bg-light border-0"
                                id="telefono"
                                placeholder="9 xxxx xxxx"
                                {...register('telefono', {required:true, minLength:9})}
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="correo" className="form-label">Correo</label>
                            <input 
                                name='correo'
                                type="email"
                                className="form-control bg-light border-0"
                                id="correo"
                                placeholder="usuario@email.com"
                                {...register('correo', {required:true})}
                            />
                        </div>
                        <button 
                            className="btn btn-primary btn-lg mt-4 mb-2 mx-auto d-block py-3 px-5 fs-2 fw-bold"
                            type="submit"
                            disabled={!formState.isValid}
                        >
                            Enviar
                        </button> 
                    </form>
                </div>     
            </Modal.Body>
            <ToastContainer />
        </Modal>
    )
}

export default PopUp